import get from '@invitato/helpers/dist/getLocale';

export default {
  title: get('Tanda Kasih', 'Wedding Gift'),
  desc: get(
    `Bagi yang ingin memberikan tanda kasih, dapat mengirimkan melalui fitur di bawah ini:`,
    `If you would like to express your kind wishes by sending us a gift, please use the button below:`,
  ),
  confirm: get('Konfirmasi', 'Confirmation'),
  sendGift: get('Kirim Hadiah', 'Send Gift'),
  modalDesc: get(
    `Bagi Bapak/Ibu/Saudara/i yang ingin memberikan tanda kasih kepada mempelai dan keluarga
    dapat melakukan transfer melalui rekening berikut:`,
    `For beloved one who may want to showing your sincere love with sending gift, please kindly tap the button down below:`,
  ),
  modalCopy: get('Salin', 'Copy'),
  successCopy: get('Teks berhasil disalin', 'Text copied'),
  modalDescAddress: get(
    `Bagi Bapak/Ibu/Saudara/i yang ingin memberikan tanda kasih kepada mempelai dan keluarga
  dapat mengirimkan hadiah di alamat berikut:`,
    `For beloved one who may want to showing your sincere love with sending gift, please kindly send to this address below:`,
  ),
};
