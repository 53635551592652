import React, { useState } from 'react';
import { string } from 'prop-types';

import { Box, Heading, Center, Button, useToast } from '@chakra-ui/react';
import LazyImage from '@components/Common/LazyImage';

import WithAnimation from '@components/Common/WithAnimation';
import ModalBank from './ModalBank';
import ModalAddress from './ModalAddress';
import ModalConfirmation from './ModalConfirmation';

import ImgPattern from '@/assets/pattern/flower.png';
import copyToClipboard from '@invitato/helpers/dist/copyTextToClipboard';
import txtWording from './locales';

import { IMG_GIFTCARD_COVER } from '@/constants/assets';
import {
  // BG_PRIMARY_DARK_GRADIENT,
  BG_PRIMARY_GRADIENT,
  BUTTON_PROPS,
  BUTTON_GRADIENT,
} from '@/constants/colors';
import {
  ENABLE_SEND_GIFT,
  ENABLE_BANK_TRANSFER,
  ENABLE_GIFT_CONFIRMATION,
} from '@/constants/feature-flags';
import BorderFrame from '@components/Common/BorderFrame';

const MODAL_STATE = {
  bank: 'bank',
  address: 'address',
  idle: '',
  confirm: 'confirmation',
};

const DEFAULT_BOTTOM = {
  color: 'white',
  marginTop: '12px',
  boxShadow: 'xl',
  fontSize: '14px',
  fontWeight: 'light',
  borderRadius: '24px',
  textTransform: 'uppercase',
  background: BUTTON_GRADIENT,
  ...BUTTON_PROPS,
};

/**
 * Function to render GiftCard component with Modal Info
 * @returns {JSX.Element}
 */
function GiftCardSection({ lang, ...rest }) {
  const [modalState, setModalState] = useState(MODAL_STATE.idle);
  const toast = useToast();

  const onCopyText = async (text, message = '') => {
    const result = await copyToClipboard(text);

    if (result) {
      setModalState(MODAL_STATE.idle);
      toast({
        description: message || `Nomor rekening berhasil disalin.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Oops. Terjadi Kendala',
        description: `Silahkan coba lagi nanti, atau refresh browser Anda.`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onClose = () => {
    setModalState(MODAL_STATE.idle);
  };

  return (
    <Box
      padding="48px 32px"
      bgColor="bgPrimary"
      bgSize="initial"
      bgImage={`linear-gradient(${BG_PRIMARY_GRADIENT}, ${BG_PRIMARY_GRADIENT}), url(${ImgPattern})`}
      {...rest}
    >
      <LazyImage
        borderTopRadius="16px"
        src={IMG_GIFTCARD_COVER}
        width="100%"
        height="200px"
        objectFit="cover"
        objectPosition="center"
      />
      <Box
        boxShadow="2xl"
        bgColor="bgSecondary"
        padding="8px"
        width="100%"
        borderBottomRadius="16px"
        pos="relative"
      >
        <BorderFrame isRSVPFrame isHeader />
        <BorderFrame isRSVPFrame />
        <Box padding="32px 24px">
          <Center>
            <WithAnimation>
              <Heading
                size="2xl"
                fontFamily="heading"
                color="alternativeColorText"
                fontWeight="normal"
              >
                {txtWording.title[lang]}
              </Heading>
            </WithAnimation>
          </Center>
          {ENABLE_BANK_TRANSFER && (
            <Center>
              <WithAnimation>
                <Button {...DEFAULT_BOTTOM} onClick={() => setModalState(MODAL_STATE.bank)}>
                  Bank Transfer
                </Button>
              </WithAnimation>
            </Center>
          )}
          {/* Send gift button section */}
          {ENABLE_SEND_GIFT && (
            <Center>
              <WithAnimation>
                <Button {...DEFAULT_BOTTOM} onClick={() => setModalState(MODAL_STATE.address)}>
                  {txtWording.sendGift[lang]}
                </Button>
              </WithAnimation>
            </Center>
          )}
          {ENABLE_GIFT_CONFIRMATION && (
            <Center>
              <WithAnimation>
                <Button {...DEFAULT_BOTTOM} onClick={() => setModalState(MODAL_STATE.confirm)}>
                  {txtWording.confirm[lang]}
                </Button>
              </WithAnimation>
            </Center>
          )}
        </Box>
        <Box pos={'relative'} style={{ pointerEvents: 'none' }}>
          <BorderFrame isRSVPFrame isFooter top="-64px" left="4px" w="calc(100% - 8px)" />
        </Box>
      </Box>
      <ModalBank
        lang={lang}
        onClose={onClose}
        visible={modalState === MODAL_STATE.bank}
        onCopyText={onCopyText}
      />
      <ModalAddress
        lang={lang}
        onClose={onClose}
        visible={modalState === MODAL_STATE.address}
        onCopyText={onCopyText}
      />
      <ModalConfirmation
        lang={lang}
        isOpen={modalState === MODAL_STATE.confirm}
        onClose={onClose}
      />
    </Box>
  );
}

GiftCardSection.propTypes = {
  lang: string,
};

GiftCardSection.defaultProps = {
  lang: 'id',
};

export default React.memo(GiftCardSection);
